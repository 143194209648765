<template>
  <footer
    class="py-4 md:p-6 xl:p-8 dark:bg-gray-800 md:flex md:items-center md:justify-between antialiased"
  >
    <div class="mb-4 xl:flex xl:items-center xl:space-x-3 md:mb-0">
      <p
        class="mb-4 text-sm text-center text-gray-500 dark:text-gray-400 xl:mb-0"
      >
        2024 &copy;
        <router-link to="/" tag="a" class="hover:underline"
          >2632202 Alberta Inc. DBA Flux OS.</router-link
        >
        All rights reserved.
      </p>
    </div>
    <div class="flex justify-center items-center space-x-3">
      <ul class="flex justify-center items-center">
        <li>
          <router-link
            to="/privacy"
            class="mr-6 text-gray-900 underline hover:no-underline dark:text-white"
            >Privacy Policy</router-link
          >
        </li>
        <li>
          <router-link
            to="/terms"
            tag="a"
            href="#"
            class="mr-6 text-gray-900 underline hover:no-underline dark:text-white"
            >Terms of Use</router-link
          >
        </li>
        <li>
          <router-link
            tag="a"
            to="/license-agreement"
            class="mr-6 text-gray-900 underline hover:no-underline dark:text-white"
            >License Agreement</router-link
          >
        </li>
        <li>
          <router-link
            to="/policies"
            tag="a"
            class="text-gray-900 underline hover:no-underline dark:text-white"
            >Policies</router-link
          >
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  name: "ApplicationFooterSection",
};
</script>
