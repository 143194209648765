<template>
  <div>
    <section class="bg-white dark:bg-gray-900">
      <div class="max-w-screen-xl px-4 py-10 mx-auto">
        <div class="max-w-3xl mx-auto text-center">
          <h1
            class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl dark:text-white"
          >
            Changelog
          </h1>
          <p class="mt-4 text-base text-gray-500 sm:text-xl dark:text-gray-400">
            Last updated on August 14, 2024
          </p>
          <div class="mt-6">
            <a
              href="#"
              title=""
              class="text-white items-center justify-center inline-flex bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Contact us
            </a>
          </div>
        </div>

        <div
          class="max-w-3xl mx-auto mt-8 sm:mt-12 lg:mt-16"
          v-for="log of changelog"
          :key="'changelog-' + log.id"
        >
          <h3 class="mt-8 text-2xl font-bold text-gray-900 dark:text-white">
            {{ log.version }}
          </h3>
          <p
            class="mt-6 text-base font-normal text-gray-500 dark:text-gray-400"
          >
            {{ log.description }}
          </p>
          <ul
            class="space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400 mt-8"
          >
            <li
              v-for="update in log.updates"
              :key="'changelog-' + changelog.id + '-update-' + update.id"
            >
              {{ update.description }}
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "ChangelogView",
  data: function () {
    return {
      changelog: [
        {
          id: 1,
          version: "v0.0.1",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          updates: [
            {
              id: 1,
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
            },
            {
              id: 2,
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
            },
          ],
        },
        {
          id: 2,
          version: "v0.0.2",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          updates: [
            {
              id: 1,
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
            },
            {
              id: 2,
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
            },
          ],
        },
      ],
    };
  },
};
</script>
