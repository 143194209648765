<template>
  <section
    class="bg-white dark:bg-gray-900 min-h-[70vh] flex items-center justify-center"
  >
    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div class="mx-auto max-w-screen-sm text-center">
        <h1
          class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-blue-600 dark:text-primary-500"
        >
          401
        </h1>
        <p
          class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white"
        >
          Unauthorized access.
        </p>
        <p class="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
          Oops, you don't have access to that page. Please reach out to your
          company admin.
        </p>
        <router-link
          to="/"
          tag="a"
          class="inline-flex text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4"
          >Back to Homepage</router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "UnauthorizedAccessView",
};
</script>
