export const getCookie = (name) => {
  // Construct the name to be searched for in the cookie string
  const nameEQ = name + "=";
  // Split the cookie string by semicolons (each cookie is separated by a semicolon)
  const ca = document.cookie.split(";");

  // Loop through the array of cookies
  for (let i = 0; i < ca.length; i++) {
    // Get each cookie and remove leading spaces
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1, c.length);
    }
    // Check if the current cookie starts with the name we're looking for
    if (c.indexOf(nameEQ) == 0) {
      // Return the value of the cookie (excluding the name and the equals sign)
      return c.substring(nameEQ.length, c.length);
    }
  }
  // Return null if the cookie is not found
  return null;
};
