<template>
  <section
    class="bg-white dark:bg-gray-900 min-h-[70vh] flex items-center justify-center"
  >
    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div class="mx-auto max-w-screen-sm text-center">
        <h1
          class="mb-4 text-4xl font-bold tracking-tight leading-none text-gray-900 lg:mb-6 md:text-5xl xl:text-6xl dark:text-white"
        >
          Coming soon!
        </h1>
        <p
          class="font-light text-gray-500 md:text-lg xl:text-xl dark:text-gray-400"
        >
          Are you an existing customer? Please check back here periodically for
          updates or follow our
          <router-link tag="a" to="/app/changelog">changelog</router-link> for
          updates. If not, reach out
          <router-link tag="a" to="/contact">here</router-link>, we would love
          to chat!
        </p>
        <router-link
          to="/"
          tag="a"
          class="inline-flex text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4"
          >Back to Homepage</router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "ProductView",
};
</script>
