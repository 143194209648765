<template>
  <div class="h-[50vh] mx-auto flex items-center justify-center"></div>
</template>

<script>
//import api from "@/api";

export default {
  name: "CareersView",
};
</script>
