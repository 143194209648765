<template>
  <!-- Authenticated -->
  <header
    class="flex flex-col antialiased fixed left-0 right-0 top-0 z-50 bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700"
  >
    <nav
      class="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-900 order-1"
    >
      <div class="flex justify-between items-center">
        <div class="flex justify-start items-center">
          <router-link to="/" tag="a" class="flex mr-6">
            <img
              src="https://flowbite.s3.amazonaws.com/logo.svg"
              class="mr-3 h-8"
              alt="Flux OS Logo"
            />
            <span
              class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"
              >Flux</span
            >
          </router-link>
        </div>
        <div class="flex justify-between items-center lg:order-2">
          <ul
            class="hidden flex-col mt-0 mr-4 w-full text-sm font-medium text-gray-900 md:flex md:flex-row dark:text-white"
          >
            <li>
              <router-link
                tag="a"
                to="/app/changelog"
                class="py-3 px-4 hover:text-primary-600 dark:hover:text-primary-500"
                >Changelog</router-link
              >
            </li>
            <li>
              <router-link
                to="/app/support"
                tag="a"
                class="py-3 px-4 hover:text-primary-600 dark:hover:text-primary-500"
                aria-current="page"
                >Support</router-link
              >
            </li>
            <li>
              <router-link
                to="/docs"
                tag="a"
                class="py-3 px-4 hover:text-primary-600 dark:hover:text-primary-500"
                aria-current="page"
                >Docs</router-link
              >
            </li>
          </ul>
          <button
            type="button"
            class="hidden py-2 px-4 md:inline-block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            v-on:click="logout()"
          >
            Logout
          </button>
          <button
            type="button"
            id="toggleMobileMenuButton"
            data-collapse-toggle="toggleMobileMenu"
            class="items-center p-2 text-gray-500 rounded-lg md:ml-2 md:hidden hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
          >
            <span class="sr-only">Open menu</span>
            <svg
              class="w-[18px] h-[18px]"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { StytchB2BHeadlessClient } from "@stytch/vanilla-js/b2b/headless";

export default {
  name: "ApplicationNavSection",
  methods: {
    logout: function () {
      const stytch = new StytchB2BHeadlessClient(
        this.$store.getters["auth/stytch_pk"]
      );
      stytch.session.revoke();
    },
  },
};
</script>
