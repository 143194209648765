const userService = (api) => ({
  getProfile: async () => {
    return api.request({
      method: "get",
      url: "/api/v1/profile",
    });
  },
});

export default userService;
