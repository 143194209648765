<template>
  <div></div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { StytchB2BUIClient } from "@stytch/vanilla-js/b2b";

export default {
  name: "LogoutView",
  mounted: function () {
    const stytch = new StytchB2BUIClient(this.$store.getters["auth/stytch_pk"]);
    console.log(stytch);
    stytch.session.revoke();
  },
};
</script>
