import axios from "axios";
import router from "./router";
import store from "./store";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error);
    if (
      (error.response.status === 403 || error.response.status === 401) &&
      new URL(error.config.url).origin == new URL(store.state.root_url).origin
    ) {
      router.push("/logout");
    }
    return Promise.reject(error);
  }
);
