import Vue from "vue";
import Vuex from "vuex";

import app from "./modules/app";
import auth from "./modules/auth";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    root_url: "https://app-web-app.onrender.com", //"http://localhost:8000",
    pusher_pk: "3c5e9c75062697acf611",
  },
  modules: {
    app,
    auth,
  },
});

export default store;
