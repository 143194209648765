<template>
  <div></div>
</template>

<script>
//import api from "@/api";

export default {
  name: "DocsView",
};
</script>
