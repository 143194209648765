import api from "@/api";

const state = {
  authenticated: localStorage.getItem("authenticated") || false,
  loading: false,
  stytch_pk: "public-token-live-ff262015-aafb-4422-9379-4b0b0560c2f6",
  user: null,
  organization: null,
};

const mutations = {
  startLoading(state) {
    state.loading = true;
  },
  stopLoading(state) {
    state.loading = false;
  },
  setAuthenticated(state) {
    state.authenticated = true;
    localStorage.setItem("authenticated", true);
  },
  unsetAuthenticated(state) {
    state.authenticated = false;
    localStorage.removeItem("authenticated");
  },
  setUser(state, user) {
    state.user = user;
  },
  unsetUser(state) {
    state.user = null;
  },
  setOrganization(state, organization) {
    state.organization = organization;
  },
  unsetOrganization(state) {
    state.organization = null;
  },
};

const actions = {
  setAuthenticated({ commit }) {
    commit("setAuthenticated");
  },
  async getUser({ commit }) {
    await api.user.getUser().then((response) => {
      commit("setUser", response);
    });
  },
  logout({ commit }) {
    commit("unsetAuthenticated");
    commit("unsetUser");
    commit("unsetOrganization");
  },
};

const getters = {
  loading: (state) => state.loading,
  authenticated: (state) => state.authenticated,
  user: (state) => state.user,
  stytch_pk: (state) => state.stytch_pk,
  organization: (state) => state.organization,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
