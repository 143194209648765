import axios from "axios";
import userService from "./user";
import { getCookie } from "@/utils";

class API {
  constructor(rootUrl) {
    this.rootUrl = rootUrl;
  }

  async request({ method, url, data = null, params = null }) {
    try {
      const response = await axios({
        method,
        url: `${this.rootUrl}${url}`,
        data,
        params,
        headers: {
          stytch_auth: getCookie("stytch_session"),
        },
      });
      return response.data;
    } catch (error) {
      console.error(`Error making ${method} request to ${url}:`, error);
      throw error;
    }
  }
}

const instance = new API(process.env.VUE_APP_ROOT_URL);

const api = {
  user: userService(instance),
};

export default api;
